import { Show, Switch, Match, type Component } from "solid-js";
import { FormattedCart } from "~/routes/(layout)/checkout/[cart_id]";
import { ProductImage } from "~/components/product";
import { imageUrl } from "~/utils/products";
import { PT } from "~/utils/products";
import { StackLineItem } from "~/services/roma-api/cart/types-lineItems";
import { OrderLineItem } from "~/services/roma-api/admin/orders";

// TODO - refactor this - very messy. Could be used in all checkout/sidepanel/product images etc..

// add props that could be useful for refactor
type LineImageProps = {
  moulding: string; // or string[] for stacks? or make StackImage its own comp
  rootClassList: { [key: string]: boolean | (() => boolean) }; // remove the RequiresBackorderSelection from this comp, leave up to parent comp
  size?: "xs" | "sm"; //etc... OR define a width?:
  // note on size..currently was using breakpoints to alter size, this causes a layout shift..maybe just let the parent component wrap and control size?
  // or take  alook at container queries?
  // yeah fuck breakpoints - this should just be width 100%, let the parent size it with css, provide a width val to grab from cdn
  width?: number;
  type: PT;
};

type FormattedLine = FormattedCart["Lines"][number];

export const LineImage: Component<{
  line: FormattedLine | OrderLineItem; // or CartDetail['Lines'][number] ?
  size?: string | "xs" | "sm"; // add sm/md/lg ?
}> = (props) => {
  const item = () => props.line?.SubItems[0];
  return (
    <>
      <Show
        when={props.line.Type == "stack"}
        fallback={
          <div
            class=" flex-shrink-0 bg-roma-grey grow"
            classList={{
              "w-28 h-28": !props.size,
              "w-20 h-20": props.size == "sm", // was small
              "w-10 h-10": props.size == "xs",
              "border border-red-100  !bg-white":
                !!(props.line as FormattedLine)?.RequiresBackorderSelection,
            }}
          >
            <Show
              when={item()?.Moulding}
              fallback={
                <div class="flex h-full items-center justify-center">
                  <div class="w-10 aspect-square border-3 border-gray-200 flex items-center justify-center">
                    <div class="w-[65%] aspect-square border-3 ml-px border-gray-200" />
                  </div>
                </div>
              }
            >
              <ProductImage
                src={imageUrl(item()?.Moulding, props.line.Type, 1)}
                width={200}
                height={200}
                quality={90}
                alt=""
                classList={{
                  "p-1": props.size == "xs",
                  "p-2":
                    props.line.Type !== PT.GALLERYFRAME && props.size !== "xs",
                  "p-0": props.line.Type == PT.GALLERYFRAME,
                }}
              />
            </Show>
          </div>
        }
      >
        <Switch>
          <Match when={props.line.SubItems.length == 2}>
            <span
              class="inline-block flex-shrink-0 bg-roma-grey grow relative"
              classList={{
                "w-28 h-28": !props.size,
                "w-20 h-20": props.size == "sm",
              }}
            >
              <div
                class="bg-white absolute top-2 left-2"
                classList={{
                  "w-16 h-16": !props.size,
                  "w-10 h-10": props.size == "sm",
                }}
              >
                <ProductImage
                  src={imageUrl(
                    (props.line as StackLineItem).FrameSize?.Layers?.O,
                    "stack",
                    1
                  )}
                  width={60}
                  height={60}
                  quality={90}
                  alt=""
                  class="p-1"
                />
              </div>
              <div
                class="bg-white absolute bottom-2 right-2 border-roma-grey border-2 box-content"
                classList={{
                  "w-16 h-16": !props.size,
                  "w-10 h-10": props.size == "sm",
                }}
              >
                <ProductImage
                  src={imageUrl(
                    (props.line as StackLineItem).FrameSize?.Layers?.I,
                    "stack",
                    1
                  )}
                  width={60}
                  height={60}
                  quality={90}
                  alt=""
                  class="p-1"
                />
              </div>
            </span>
          </Match>
          <Match when={props.line.SubItems.length == 3}>
            <span
              class="inline-block flex-shrink-0 bg-roma-grey grow relative"
              classList={{
                "w-28 h-28": !props.size,
                "w-20 h-20": props.size == "sm",
              }}
            >
              <div
                class="bg-white absolute"
                classList={{
                  "w-12 h-12 top-2 left-2": !props.size,
                  "w-8 h-8 top-1 left-1": props.size == "sm",
                }}
              >
                <ProductImage
                  src={imageUrl(
                    (props.line as StackLineItem).FrameSize?.Layers?.O,
                    "stack",
                    1
                  )}
                  alt=""
                  width={60}
                  height={60}
                  quality={90}
                  class="p-1"
                />
              </div>
              <div
                class=" bg-white absolute  border-roma-grey border-2 box-content"
                classList={{
                  "w-12 h-12 bottom-8 left-8": !props.size,
                  "w-8 h-8 bottom-5 left-5": props.size == "sm",
                }}
              >
                <ProductImage
                  src={imageUrl(
                    (props.line as StackLineItem).FrameSize?.Layers
                      ?.M2 as string,
                    "stack",
                    1
                  )}
                  width={60}
                  height={60}
                  quality={90}
                  alt=""
                  class="p-1"
                />
              </div>
              <div
                class="bg-white absolute border-roma-grey border-2 box-content"
                classList={{
                  "w-12 h-12 bottom-2 right-2": !props.size,
                  "w-8 h-8 bottom-1 right-1": props.size == "sm",
                }}
              >
                <ProductImage
                  src={imageUrl(
                    (props.line as StackLineItem).FrameSize?.Layers?.I,
                    "stack",
                    1
                  )}
                  width={60}
                  height={60}
                  quality={90}
                  alt=""
                  class="p-1"
                />
              </div>
            </span>
          </Match>
        </Switch>
      </Show>
    </>
  );
};
